@media (prefers-color-scheme: light) {
  .react-calendar-heatmap .color-empty {
  }

  .react-calendar-heatmap .color-scale-1 {
    fill: #c8e6c9; /* 100 */
  }
  .react-calendar-heatmap .color-scale-2 {
    fill: #a5d6a7; /* 200 */
  }
  .react-calendar-heatmap .color-scale-3 {
    fill: #81c784; /* 300 */
  }
  .react-calendar-heatmap .color-scale-4 {
    fill: #66bb6a; /* 400 */
  }

  .card-color-scale-0 {
  }

  .card-color-scale-1 {
    background-color: #c8e6c9 !important; /* 100 */
  }
  .card-color-scale-2 {
    background-color: #a5d6a7 !important; /* 200 */
  }
  .card-color-scale-3 {
    background-color: #81c784 !important; /* 300 */
  }
  .card-color-scale-4 {
    background-color: #66bb6a !important; /* 400 */
  }
}

@media (prefers-color-scheme: dark) {
  .react-calendar-heatmap .color-empty {
    fill: #424242; /* grey 800 */
  }
  .react-calendar-heatmap .color-scale-1 {
    fill: #1b5e20; /* green 900 */
  }
  .react-calendar-heatmap .color-scale-2 {
    fill: #2e7d32; /* green 800 */
  }
  .react-calendar-heatmap .color-scale-3 {
    fill: #388e3c; /* green 700 */
  }
  .react-calendar-heatmap .color-scale-4 {
    fill: #43a047; /* green 600 */
  }

  .card-color-empty {
    background-color: #424242 !important; /* grey 800 */
  }
  .card-color-scale-1 {
    background-color: #1b5e20 !important; /* green 900 */
  }
  .card-color-scale-2 {
    background-color: #2e7d32 !important; /* green 800 */
  }
  .card-color-scale-3 {
    background-color: #388e3c !important; /* green 700 */
  }
  .card-color-scale-4 {
    background-color: #43a047 !important; /* green 600 */
  }
}

.react-calendar-heatmap .color-scale-5 {
  fill: #4caf50; /* green 500 */
}

.card-color-scale-5 {
  background-color: #4caf50 !important; /* green 500 */
}
